$primary-color: #084726;
$primary-color-opacity-85: rgba(8, 71, 38, 0.85);
$primary-color-opacity-75: rgba(8, 71, 38, 0.75);
$primary-color-opacity-65: rgba(8, 71, 38, 0.65);
$primary-color-opacity-25: rgba(8, 71, 38, 0.25);
$primary-color-opacity-15: rgba(8, 71, 38, 0.15);
$primary-color-opacity-50: rgba(8, 71, 38, 0.5);
$primary-color-opacity-5: rgba(8, 71, 38, 0.05);
$secondary-color: #19a45d;
$secondary-color-opacity-25: rgba(25, 164, 93, 0.25);
$secondary-color-opacity-10: rgba(25, 164, 93, 0.1);
$tertiary-color: #d5ece2;
$tertiary-color-opacity-75: rgba(213, 236, 226, 0.75);
$black-color: #1a1a1a;
$white-color: #f6f6f6;

$overlay-color: #161617;

$gray-color: #eeeeee;
$variation-gray-color: #f0f2f2;
$variation-2-gray-color: #e4e9e9;
$description-gray-color: #585858;

$warning-color: #ff7878;
$warning-light-color: #ffe7e7;
$gray-light-color: #d7d7d7;
$steps-color: #c9f3ba;
$background-color: #f0f2f0;

// New Design System

//Primary Brand

$action-green-color: #117d46;
$avocado-opacity-100: #599476;
$avocado-opacity-90: #65a776;
$avocado-opacity-80: #c9f3ba;
$avocado-opacity-10: #f0ffe6;
$dark-green-color: #084726;

//Secondary Brand

$spring-bud-color: #e8f3ba;
$pale-blue: #baf3de;

//Neutrals
$gray-opacity-5-color: #fafafa;
$gray-opacity-8-color: #4e5554;

$gray-opacity-10-color: #f0f2f2;
$gray-opacity-50-color: #868686;
$gray-opacity-60-color: #68706f;
$gray-opacity-80-color: #696969;

$black-variant-1: #2e3232;
$main-white-color: #ffffff;

//complementary
$light-lavender: #d2d7ff;
$light-pink: #fed2ff;
$light-water: #d2fff4;
$light-purple: #e6d2ff;
$light-red: #ffd2d2;

//new pay design system
$pay-gray-color: #c2c9c9;
