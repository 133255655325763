@use './font.scss' as font;
@use './style.scss' as style;


@mixin title($size:28px, $line-height:36px, $color:style.$primary-color, $font-family:font.$font-poppins-medium, $text-align:center){
    font-family: $font-family;
    color:$color;
    font-size: $size;
    line-height: $line-height;
    margin: 0;
    text-align: $text-align;
}

@mixin subtitle($size:18px, $line-height:24px, $color:style.$primary-color-opacity-75, $font-family:font.$font-nunito-semibold, $text-align:left){
    font-family: $font-family;
    color:$color;
    font-size: $size;
    line-height: $line-height;
    margin: 0;
    text-align: $text-align;
}


@mixin link($size:18px, $line-height:24px, $color:style.$primary-color, $font-family:font.$font-nunito-semibold){
    font-family: $font-family;
    color:$color;
    font-size: $size;
    line-height: $line-height;
    margin: 0;
    text-decoration: underline;
}

@mixin default-text($size:28px, $line-height:36px, $color:style.$primary-color, $font-family:font.$font-poppins-medium, $text-align:center){
    font-family: $font-family;
    color:$color;
    font-size: $size;
    line-height: $line-height;
    margin: 0;
    text-align: $text-align;
}



@mixin display-flex($gap:0, $wrap:wrap, $justify-content:center, $align-content:center, $align-items:center){
    display: flex;
    gap: $gap;
    flex-wrap: $wrap;
    justify-content: $justify-content;
    align-content: $align-content;
    align-items: $align-items;
}