/* You can add global styles to this file, and also import other style files */
@use "tailwindcss/base";
@use "tailwindcss/components";
@use "tailwindcss/utilities";
@use "./confirmation_assets/styles/style.scss" as style;
@use "./confirmation_assets/styles/mixin.scss" as mixin;
@use "./confirmation_assets/styles/font.scss" as font;
@import "primeicons/primeicons.css";

html {
  min-height: 100%;
  position: relative;
  background-color: white;
}

body {
  height: 100%;
  --maskbg: rgba(26, 26, 26, 0.75);
  margin: 0;
  background-color: style.$white-color;
  font-size: 16px;
  .container {
    padding: 0 30px;
    max-width: 390px;
    margin: auto;
  }
  .title {
    @include mixin.title();
  }

  .subtitle {
    @include mixin.subtitle();
  }

  .p-button.p-button-success:enabled:focus {
    box-shadow: none;
  }

  @keyframes moveRight {
    0% {
      background-position: left;
    }
    100% {
      background-position: right;
    }
  }
  strong {
    color: black; /* Color personalizado */
  }
  .more .custom-selected-button {
    .p-ripple {
      border: none;
      background: transparent;
      padding: 0;
      width: 45px;
      height: 45px;
      @include mixin.default-text($size: 15px, $line-height: 22px, $color: style.$primary-color-opacity-25, $font-family: font.$font-nunito-regular);
    }
    .p-highlight {
      background: style.$tertiary-color;
      color: style.$secondary-color;
      border-radius: 8px;
    }
  }

  .p-button:focus {
    box-shadow: none;
    z-index: 0;
  }
  hr {
    border-color: style.$primary-color-opacity-15;
    border-bottom: 0;
  }

  .spinner {
    width: 40px;
    height: 40px;
  }
  .pbutton-a {
    background: style.$secondary-color;
    border: none;
    margin-top: 20px;
    border-radius: 12px;
    display: flex;
    height: 55px;
    margin-top: 5px;
    @include mixin.display-flex();
    .title {
      @include mixin.title($size: 18px, $line-height: 20px, $color: style.$white-color);
    }
  }
  .disabled {
    opacity: 0.6;
    pointer-events: none;
  }
  .p-inputtext {
    border: none;
  }
  .p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border: none;
  }
  // .ng-invalid,
  // .ng-dirty{
  //   border: 1px solid style.$warning-color;
  //   border-radius: 7px;
  // }
  p-selectbutton {
    border: none !important;
  }
  .text-warning {
    @include mixin.default-text($font-family: font.$font-nunito-semibold, $size: 14px, $line-height: 21px, $color: style.$warning-color);
    text-align: left;
  }
}
:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-dialog-mask {
  backdrop-filter: blur(8px);
  background: rgb(26 26 26 / 75%);
  z-index: 9999999999 !important;
  .image {
    width: 106px;
    height: 106px;
    background: style.$secondary-color;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
  }
  .confirm-dialog {
    margin-top: -120px;
  }
  .confirmation-dialog {
    max-width: 330px;

    .exit-dialog-wrapper {
      position: absolute;
      bottom: -50px;
      width: auto;
      &__action {
        cursor: pointer;
        @include mixin.default-text($font-family: font.$font-poppins-medium, $size: 18px, $line-height: 30px, $color: style.$white-color);
      }
    }
  }
}
.custom-condition-selected-button {
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  .p-element {
    background: style.$gray-color;
    max-width: 160px;
    width: 100%;
    padding: 10px;
    border-radius: 15px;
    height: 55px;
    border: none;
  }
  .p-button-label {
    font-weight: 500;
    @include mixin.title($size: 15px, $line-height: 18px, $font-family: font.$font-poppins-regular);
    text-align: left;
  }

  .p-highlight {
    background: style.$secondary-color;
    border: none;
    .p-button-label {
      color: style.$white-color;
    }
  }
}
.color-warning {
  color: style.$warning-color;
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animation {
  animation: fadein 0.2s linear;
}

.material-symbols-outlined {
  color: style.$gray-opacity-80-color;
}

//Color class

.avocado-80 {
  background-color: style.$avocado-opacity-80 !important;
}

.pale-blue {
  background-color: style.$pale-blue !important;
}

p-skeleton {
  width: 100%;
}
