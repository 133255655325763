// Poppins
@font-face {
  font-family: "Poppins Bold";
  src: url(/confirmation_assets/fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins SemiBold";
  src: url(/confirmation_assets/fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins Medium";
  src: url(/confirmation_assets/fonts/Poppins/Poppins-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins Regular";
  src: url(/confirmation_assets/fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}

// Nunito
@font-face {
  font-family: "Nunito Bold";
  src: url(/confirmation_assets/fonts/Nunito/Nunito-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Nunito SemiBold";
  src: url(/confirmation_assets/fonts/Nunito/Nunito-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Nunito Medium";
  src: url(/confirmation_assets/fonts/Nunito/Nunito-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Nunito Regular";
  src: url(/confirmation_assets/fonts/Nunito/Nunito-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Nunito Light";
  src: url(/confirmation_assets/fonts/Nunito/Nunito-Light.ttf) format("truetype");
}

//Nunito Sans
@font-face {
  font-family: "Nunito Sans ExtraBold";
  src: url(/confirmation_assets/fonts/Nunito-Sans/NunitoSans_10pt-ExtraBold.ttf) format("truetype");
}
@font-face {
  font-family: "Nunito Sans Bold";
  src: url(/confirmation_assets/fonts/Nunito-Sans/NunitoSans_10pt-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Nunito Sans SemiBold";
  src: url(/confirmation_assets/fonts/Nunito-Sans/NunitoSans_10pt-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Nunito Sans Medium";
  src: url(/confirmation_assets/fonts/Nunito-Sans/NunitoSans_10pt-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Nunito Sans Regular";
  src: url(/confirmation_assets/fonts/Nunito-Sans/NunitoSans_10pt-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Nunito Light";
  src: url(/confirmation_assets/fonts/Nunito-Sans/NunitoSans_10pt-Light.ttf) format("truetype");
}

// Poppins
$font-poppins-bold: "Poppins Bold", sans-serif;
$font-poppins-semibold: "Poppins SemiBold", sans-serif;
$font-poppins-medium: "Poppins Medium", sans-serif;
$font-poppins-regular: "Poppins Regular", sans-serif;

// Nunito
$font-nunito-bold: "Nunito Bold", sans-serif;
$font-nunito-semibold: "Nunito SemiBold", sans-serif;
$font-nunito-medium: "Nunito Medium", sans-serif;
$font-nunito-regular: "Nunito Regular", sans-serif;
$font-nunito-light: "Nunito Light", sans-serif;

// Nunito Sans
$font-nunito-sans-extrabold: "Nunito Sans Extra Bold", sans-serif;
$font-nunito-sans-bold: "Nunito Sans Bold", sans-serif;
$font-nunito-sans-semibold: "Nunito Sans SemiBold", sans-serif;
$font-nunito-sans-medium: "Nunito Sans Medium", sans-serif;
$font-nunito-sans-regular: "Nunito Sans Regular", sans-serif;
$font-nunito-sans-light: "Nunito Sans Light", sans-serif;
